<template>
    <div>
        <router-view />
        <router-link class="return" :to="backUrl">＜</router-link>
        <div class="nav">
            <ul class="menu">
                <li>
                    <router-link to="/course/find" active-class="on">
                        <img src="@/assets/images/course/find.png" alt="">
                        <img src="@/assets/images/course/find_on.png" alt="" class="imgOn">
                        <br>
                        发现
                    </router-link>
                </li>
                <li>
                     <router-link to="/course/buy" active-class="on">
                        <img src="@/assets/images/course/buy.png" alt="">
                        <img src="@/assets/images/course/buy_on.png" alt="" class="imgOn">
                        <br>
                        已购
                    </router-link>                    
                </li>
                <li>
                    <router-link to="/course/order" active-class="on">
                        <img src="@/assets/images/course/order.png" alt="">
                        <img src="@/assets/images/course/order_on.png" alt="" class="imgOn">
                        <br>
                        订单
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
            title: '',
            backUrl: '',
            // uid:Vue.getUser().uid,
        }
    },
    watch: {
        $route(to, from) {
            this.title = to.name;
            // if (from.fullPath != '/login' && from.fullPath != '/order' && from.fullPath != '/mine') {
            //     this.backUrl = from.fullPath
            // }
        }
    },
    mounted() {
        this.title = this.$route.name;
    }
}
</script>
<style lang="scss" scoped>
.return {
    display: none;
}
.nav {
    height: 98px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: -2px 0 5px 2px #f2f2f2;
    .menu {
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            flex-grow: 1;
            text-align: center;
            font-size: 22px;
            color: #656565;
            line-height: 30px;
            img {
                width: 42px;
                &.imgOn {
                    display: none;
                }
            }
            .on {
                color: #d51f13;
                img {
                    display: none;
                    &.imgOn {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>